import * as React from 'react';
import { useEffect } from 'react';

import { Hero, Layout, SEO } from '../components';
import MovingQuoteForm from '../components/forms/moving-quote-form';
import Banner from '../components/moving/moving-banner';
import CTA from '../components/moving/moving-cta';
import FirstSection from '../components/moving/moving-first-section';
import HeroImage from '../components/moving/moving-hero-image';
import SecondSection from '../components/moving/moving-second-section';
import Solutions from '../components/solutions';

const MovingPage = () => { 
  useEffect(() => {
    if (typeof window !== 'undefined') {
      var f = document.createElement("iframe");   
f.src = 'https://forms.zohopublic.com.au/frontlineremovals/form/InstantremovalsquoteForm/formperma/Oqky3wG95lAVUwCtZhkyaK0t70ToNhwOCPbluUV2Z-o?zf_rszfm=1';
f.style.border="none";                                           
f.style.height="655px";
f.style.width="100%";
f.style.zIndex="50";
f.style.transition="all 0.5s ease";// No I18N
var d = document.getElementById("moving-page-top-form");
d.appendChild(f);
// window.addEventListener('message', function (){
// var zf_ifrm_data = typeof event.data === 'string' ? event.data.split("|") : null ;
// var zf_perma = zf_ifrm_data && zf_ifrm_data[0];
// // var zf_ifrm_ht_nw = zf_ifrm_data && z( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
// var iframe = document.getElementById("zf_div_hkM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM").getElementsByTagName("iframe")[0];
// if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
// // var prevIframeHeight = iframe.style.height;
// // if ( prevIframeHeight != zf_ifrm_ht_nw ) {
// // iframe.style.height = zf_ifrm_ht_nw;
// // }   
// }
// }, false);
      }
  
}, []);
  return(
  <Layout formName="moving-page-bottom-form">
    <SEO
      title="Interstate Furniture Removals | Frontline Removals & Storage"
      description="New South Wales interstate furniture removals and relocation company. Frontline Removals offers reliable & fast shipping of your belongings. Get a quote now."
    />
    <Hero image={<HeroImage />} cta={<CTA />} />
    {/* <MovingQuoteForm formName="moving-page-top-form" className="-mt-24" /> */}
    <div id="moving-page-top-form" className='relative max-w-4xl mx-auto -mt-36'></div>
    <FirstSection />
    <Solutions />
    <SecondSection />
    <Banner />
  </Layout>
)};

export default MovingPage;
